<template>
  <v-app-bar
    id="default-app-bar"
    app
    absolute
    class="v-bar--underline"
    color="transparent"
    :clipped-left="rtl"
    :clipped-right="!rtl"
    height="70"
    flat
  >
    

    <v-toolbar-title class="font-weight-light text-h3" v-text="getTitle" />

    <v-spacer />    
      
    
    <v-menu class="ml-20" v-if="loggedIn">      
      <template v-slot:activator="{ on, attrs }">
        <v-avatar color="primary">
        <v-btn text v-bind="attrs" v-on="on">
          <span class="white--text text-h5">{{ getInitials }}</span>            
        </v-btn>
          </v-avatar>
        
      </template>
      <v-list width="250">
        <v-list-item>
          <h6 class="text-h6">{{ getUsername }}</h6>
        </v-list-item> 
        <v-list-item v-if="isUser">
          <v-radio-group
              v-model="selectedApotheke"
              column
            >
            <v-radio
              v-for="a in getApotheken"
              :key="a.id"
              :label="`${a.name}`"
              :value="a"              
              @click="selectApotheke()"
              class="black--text"
            ></v-radio>      
          </v-radio-group>


        </v-list-item> 
        <v-list-item>
          <v-btn plain v-on:click="setPassword">Passwort ändern</v-btn>
        </v-list-item>       
        <v-list-item>
          <v-btn color="primary" class="ml-4" v-on:click="logout"> {{ $t('logout') }} </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-btn v-else text v-on:click="gotoLogin">
      <h6 class="text-h6">{{ $t('login') }}</h6>
    </v-btn>
  </v-app-bar>
</template>

<script>

export default {
  name: "DefaultBar",

  components: {
    
  },

  data() {
    return {            
      selectedApotheke: null
    };
  },

  computed: {
    loggedIn() {
      return this.$store.state.loggedIn;
    },
    drawer() {
      return this.$store.state.drawer;
    },
    drawerImage() {
      return this.$store.state.drawerImage;
    },
    rtl() {
      return this.$store.state.rtl;
    },
    getInitials() {
      return this.$store.getters.getInitials;
    },
    getUsername() {
      return this.$store.getters.getUsername;
    },
    getRole() {
      return this.$store.getters.getRole;
    },
    isUser() {
      return this.$store.getters.getRole === 'user' || this.$store.getters.getRole === 'supervisor';
    },
    getEmail() {
      return this.$store.getters.getEmail;
    },
    getTitle() {
      return this.$store.state.pageTitle;
    },
    getApotheke() {
      return this.$store.state.selectedApotheke;    
    },
    getApotheken() {
      return this.$store.state.apotheken;
    },
  },

  methods: {    
    logout() {
      this.$store.commit("changeToken", "");
      this.$store.commit("changeLoggedIn", false);
      this.$store.commit('setApotheken', null);
      this.$store.commit('setSelectedApotheke', null);
      this.$store.commit('setPageTitle', "login");
      this.$router.push("/login");
    },
    gotoLogin() {      
      this.$router.push("/login");
    },
    changeDrawer() {
      this.$store.commit("changeDrawer", !this.drawer);
    },
    changeDrawerIamge() {
      this.$store.commit("changeDrawerImage", !this.drawer);
    },
    changeLanguage() {
      if(this.$i18n.locale === 'en') {
        this.$i18n.locale = 'de';
      }
      else {
        this.$i18n.locale = 'en';
      }
    },
    setSelectedApotheke() {                 
        this.selectedApotheke = this.$store.getters.getApotheke; 
        if(this.selectedApotheke.waweeAktivSeit) {        
        const datum = new Date(Date.parse(this.selectedApotheke.waweeAktivSeit));      
        this.$store.commit('setPageTitle', this.selectedApotheke.name + ' - wawee! aktiv seit ' + datum.toLocaleDateString('de-DE'));      
        }
        else {
          this.$store.commit('setPageTitle', this.selectedApotheke.name);      
        }
    },
    selectApotheke() {
      this.$router.push("/dashboard/" + this.selectedApotheke.id);
    },
    setPassword() {
      this.$router.push("/newpassword");
    }
  },  
  created() {
      this.$eventHub.$on('apothekeSelected', this.setSelectedApotheke);
  },
  beforeDestroy() {
    this.$eventHub.$off('apothekeSelected');
  },
};
</script>
<style scoped>
.black--text /deep/ label {
    color: black;
}
</style>